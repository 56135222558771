"use strict";
import { screens } from "tailwindcss/defaultTheme";
let prevScroll = window.scrollY;
const stickyHeader = document.querySelector("[data-sticky-header]");
const footer = document.querySelector("footer");
const stickyButtons = document.querySelectorAll("[data-sticky-buttons]");
const isElementInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
};
const adjustCtasButtons = () => {
  if (!stickyButtons || !stickyButtons.length) {
    return;
  }
  let visibleButtons = Array.from(stickyButtons).filter(isElementInViewport);
  if (visibleButtons.length > 0) {
    const marginBottom = Math.max(...visibleButtons.map((ctasButton) => ctasButton.offsetHeight));
    footer.style.marginBottom = `${marginBottom}px`;
  } else {
    footer.style.marginBottom = 0;
  }
};
const fixMenuBarContainerMargin = () => {
  if (!stickyHeader) {
    return;
  }
  const isMaxScreenSize = (key) => {
    return window.matchMedia(`(max-width: ${screens[key]})`).matches;
  };
  const menuBarContainer = document.querySelector("#menu-bar-container");
  const marginTop = isMaxScreenSize("md") ? stickyHeader.offsetHeight : 0;
  menuBarContainer.style.marginTop = `${marginTop}px`;
};
document.addEventListener("DOMContentLoaded", () => {
  fixMenuBarContainerMargin();
});
window.addEventListener("resize", () => {
  fixMenuBarContainerMargin();
});
if (stickyHeader) {
  document.addEventListener("scroll", () => {
    fixMenuBarContainerMargin();
    const header = document.getElementById("main-bar").offsetParent;
    if (header && window.getComputedStyle(stickyHeader).position === "fixed") {
      let currentScroll = window.scrollY;
      let goingDown = prevScroll > currentScroll;
      let change = Math.abs(prevScroll - currentScroll);
      if (change > 5) {
        if (goingDown || currentScroll < stickyHeader.offsetHeight) {
          stickyHeader.style.top = 0;
        } else {
          stickyHeader.style.top = `-${stickyHeader.offsetHeight}px`;
        }
        prevScroll = currentScroll;
      }
      adjustCtasButtons();
    }
  });
  document.addEventListener("on:toggle", () => {
    adjustCtasButtons();
  });
}
;
