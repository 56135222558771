"use strict";
const changeLabel = function(input) {
  let submit = input.closest("form").querySelector("button[type=submit]");
  if (submit.querySelector("span") !== null) {
    submit = submit.querySelector("span");
  }
  if (input.checked === true) {
    submit.innerHTML = input.dataset.labelAction;
  } else {
    submit.innerHTML = input.dataset.labelReport;
  }
};
export default function changeReportFormBehavior(container) {
  container.querySelectorAll("[data-hide=true]").forEach((checkbox) => {
    checkbox.addEventListener("change", (event) => {
      changeLabel(event.target);
    });
  });
  container.querySelectorAll("[data-block=true]").forEach((checkbox) => {
    checkbox.addEventListener("change", (event) => {
      changeLabel(event.target);
      let blockAndHide = event.target.closest("form").querySelector("#block_and_hide");
      blockAndHide.classList.toggle("invisible");
    });
  });
}
