"use strict";
class AbideFormValidatorFixer {
  initialize() {
    const forms = document.querySelectorAll("main [data-live-validate='true']");
    forms.forEach((form) => {
      if (this.isElementVisible(form)) {
        this.setupForm(form);
      }
    });
  }
  isElementVisible(element) {
    return element.offsetParent !== null && getComputedStyle(element).display !== "none";
  }
  setupForm(form) {
    const inputs = form.querySelectorAll("input");
    inputs.forEach((input) => {
      var _a;
      const errorElement = ((_a = input.closest("label")) == null ? void 0 : _a.querySelector(".form-error")) || input.parentElement.querySelector(".form-error");
      if (!errorElement) {
        return;
      }
      form.removeAttribute("data-live-validate");
      input.addEventListener("input", this.hideErrorElement.bind(this, errorElement));
    });
  }
  hideErrorElement(errorElement) {
    errorElement.classList.remove("is-visible");
  }
}
document.addEventListener("DOMContentLoaded", () => {
  const validatorFixer = new AbideFormValidatorFixer();
  validatorFixer.initialize();
});
