"use strict";
import Tribute from "src/decidim/vendor/tribute";
$(() => {
  const $hashtagContainer = $(".js-hashtags");
  const nodatafound = $hashtagContainer.attr("data-noresults");
  if ($hashtagContainer.parent().hasClass("editor")) {
    return;
  }
  let noMatchTemplate = null;
  if (nodatafound) {
    noMatchTemplate = () => `<li>${nodatafound}</li>`;
  }
  let remoteSearch = function(text, cb) {
    $.post(window.Decidim.config.get("api_path"), { query: `{hashtags(name:"${text}") {name}}` }).then((response) => {
      let data = response.data.hashtags || {};
      cb(data);
    }).fail(function() {
      cb([]);
    }).always(() => {
      const $parent = $(tribute.current.element).parent();
      $parent.addClass("is-active");
      const $tribute = $parent.find(".tribute-container");
      $tribute.removeAttr("style");
    });
  };
  let tribute = new Tribute({
    trigger: "#",
    values: function(text, cb) {
      remoteSearch(text, (hashtags) => cb(hashtags));
    },
    positionMenu: true,
    menuContainer: null,
    fillAttr: "name",
    noMatchTemplate,
    lookup: (item) => item.name,
    selectTemplate: function(item) {
      if (typeof item === "undefined") {
        return null;
      }
      return `#${item.original.name}`;
    },
    menuItemTemplate: function(item) {
      let tpl = `<strong>${item.original.name}</strong>`;
      return tpl;
    }
  });
  $hashtagContainer.on("focusin", (event) => {
    tribute.menuContainer = event.target.parentNode;
  });
  $hashtagContainer.on("focusout", (event) => {
    let $parent = $(event.target).parent();
    if ($parent.hasClass("is-active")) {
      $parent.removeClass("is-active");
    }
  });
  $hashtagContainer.on("input", (event) => {
    let $parent = $(event.target).parent();
    if (tribute.isActive) {
      let $tribute = $(".tribute-container");
      $tribute.appendTo($parent);
      $parent.addClass("is-active");
    } else {
      $parent.removeClass("is-active");
    }
  });
});
