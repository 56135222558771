"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import icon from "src/decidim/icon";
const DEFAULT_MESSAGES = {
  externalLink: "External link",
  opensInNewTab: "Opens in new tab"
};
let MESSAGES = DEFAULT_MESSAGES;
export default class ExternalLink {
  static configureMessages(messages) {
    MESSAGES = __spreadValues(__spreadValues({}, DEFAULT_MESSAGES), messages);
  }
  constructor(node) {
    if (node.closest(".editor-container")) {
      return;
    }
    if (!node.querySelector("span[data-external-link]")) {
      if (node.dataset.externalLink === "text-only") {
        this.setupTextOnly(node);
      } else {
        this.setup(node);
      }
    }
  }
  setup(node) {
    const span = document.createElement("span");
    span.dataset.externalLink = true;
    span.innerHTML = `${this.generateIcon()}${this.generateScreenReaderLabel(node)}`;
    span.classList.add("inline-block", "mx-0.5");
    return node.appendChild(span);
  }
  setupTextOnly(node) {
    const dummy = document.createElement("span");
    dummy.innerHTML = this.generateScreenReaderLabel(node);
    return node.appendChild(dummy.firstChild);
  }
  generateIcon() {
    return icon("external-link-line", { class: "fill-current" });
  }
  generateScreenReaderLabel(node) {
    let text = MESSAGES.opensInNewTab;
    if (this._isExternalLink(node)) {
      text = MESSAGES.externalLink;
    }
    return `<span class="sr-only">(${text})</span>`;
  }
  _isExternalLink(node) {
    const externalMatches = [
      // Links to the internal link page /link?external_url=https%3A%2F%2Fdecidim.org
      new RegExp("^/link\\?external_url="),
      // Links starting with http/s and not to the current host
      new RegExp(`^https?://((?!${location.host}).)+`)
    ];
    const href = node.getAttribute("href") || "";
    return externalMatches.some((regexp) => href.match(regexp));
  }
}
