"use strict";
import UploadModal from "src/decidim/direct_uploads/upload_modal";
import { truncateFilename } from "src/decidim/direct_uploads/upload_utility";
import { escapeHtml, escapeQuotes } from "src/decidim/utilities/text";
const updateModalTitle = (modal) => {
  if (modal.uploadItems.children.length === 0) {
    modal.modalTitle.innerHTML = modal.modalTitle.dataset.addlabel;
  } else {
    modal.modalTitle.innerHTML = modal.modalTitle.dataset.editlabel;
  }
  modal.updateDropZone();
};
const updateActiveUploads = (modal) => {
  const defaultFile = document.getElementById(`default-active-${modal.modal.id}`);
  if (defaultFile) {
    defaultFile.remove();
  }
  const files = document.querySelector(`[data-active-uploads=${modal.modal.id}]`);
  const previousId = Array.from(files.querySelectorAll("[type=hidden][id]"));
  const isMultiple = modal.options.multiple;
  files.textContent = "";
  const [removeFiles, addFiles] = [modal.items.filter(({ removable }) => removable), modal.items.filter(({ removable }) => !removable)];
  addFiles.forEach((file, ix) => {
    let title = truncateFilename(file.name, 19);
    let hidden = "";
    if (file.hiddenField) {
      const fileField = isMultiple ? `${modal.options.resourceName}[${modal.options.addAttribute}][${ix}][file]` : `${modal.options.resourceName}[${modal.options.addAttribute}]`;
      hidden = `<input type="hidden" name="${fileField}" value="${file.hiddenField}" />`;
    } else {
      const fileField = isMultiple ? `${modal.options.resourceName}[${modal.options.addAttribute}][${ix}][id]` : `${modal.options.resourceName}[${modal.options.addAttribute}]`;
      const attributes = Array.from(previousId.find(({ id }) => id === file.attachmentId).attributes).reduce((acc, { name, value }) => `${acc} ${name}="${value}"`, "");
      hidden = `<input ${attributes} />`;
      hidden += `<input type="hidden" name="${fileField}" value="${file.attachmentId}" />`;
    }
    if (modal.options.titled) {
      const titleValue = modal.modal.querySelectorAll('input[type="text"]')[ix].value;
      const titleField = `${modal.options.resourceName}[${modal.options.addAttribute}][${ix}][title]`;
      hidden += `<input type="hidden" name="${titleField}" value="${escapeQuotes(titleValue)}" />`;
      title = titleValue;
    }
    const attachmentIdOrHiddenField = file.attachmentId ? `data-attachment-id="${file.attachmentId}"` : `data-hidden-field="${file.hiddenField}"`;
    const template = `
      <div ${attachmentIdOrHiddenField} data-filename="${escapeQuotes(file.name)}" data-title="${escapeQuotes(title)}">
        ${/image/.test(file.type) && `<div><img src="data:," alt="${escapeQuotes(file.name)}" /></div>` || ""}
        <span>${escapeHtml(title)} (${escapeHtml(truncateFilename(file.name))})</span>
        ${hidden}
      </div>
    `;
    const div = document.createElement("div");
    div.innerHTML = template.trim();
    const container = div.firstChild;
    modal.autoloadImage(container, file);
    files.appendChild(container);
  });
  removeFiles.forEach(() => {
    const div = document.createElement("div");
    div.innerHTML = `<input name='${modal.options.resourceName}[remove_${modal.button.name}]' type="hidden" value="true">`;
    files.appendChild(div.firstChild);
  });
  modal.updateAddAttachmentsButton();
};
const highlightDropzone = (modal) => {
  modal.emptyItems.classList.add("is-dragover");
  modal.uploadItems.classList.add("is-dragover");
};
const resetDropzone = (modal) => {
  modal.emptyItems.classList.remove("is-dragover");
  modal.uploadItems.classList.remove("is-dragover");
};
document.addEventListener("DOMContentLoaded", () => {
  const attachmentButtons = document.querySelectorAll("button[data-upload]");
  attachmentButtons.forEach((attachmentButton) => {
    const modal = new UploadModal(attachmentButton);
    const files = document.querySelector(`[data-active-uploads=${modal.modal.id}]`);
    [...files.children].forEach((child) => modal.preloadFiles(child));
    modal.input.addEventListener("change", (event) => modal.uploadFiles(event.target.files));
    modal.button.addEventListener("click", (event) => event.preventDefault() || modal.items.length === 0 && [...files.children].forEach((child) => child.tagName === "DIV" && modal.preloadFiles(child)) || updateModalTitle(modal));
    modal.dropZone.addEventListener("dragover", (event) => event.preventDefault() || highlightDropzone(modal));
    modal.dropZone.addEventListener("dragleave", () => resetDropzone(modal));
    modal.dropZone.addEventListener("drop", (event) => event.preventDefault() || resetDropzone(modal) || modal.uploadFiles(event.dataTransfer.files));
    modal.saveButton.addEventListener("click", (event) => event.preventDefault() || updateActiveUploads(modal));
    modal.cancelButton.addEventListener("click", (event) => event.preventDefault() || modal.cleanAllFiles());
  });
});
