"use strict";
const getAbsolutePosition = (node, relativeParent) => {
  const { top, left, width, height } = node.getBoundingClientRect();
  let [pageX, pageY] = [window.pageXOffset, window.pageYOffset];
  if (relativeParent) {
    const { topLeft: [parentX, parentY] } = getAbsolutePosition(relativeParent);
    [pageX, pageY] = [pageX - parentX, pageY - parentY];
  }
  return {
    topLeft: [pageX + left, pageY + top],
    topCenter: [pageX + left + width / 2, pageY + top],
    topRight: [pageX + left + width, pageY + top],
    middleLeft: [pageX + left, pageY + top + height / 2],
    middleCenter: [pageX + left + width / 2, pageY + top + height / 2],
    middleRight: [pageX + left + width, pageY + top + height / 2],
    bottomLeft: [pageX + left, pageY + top + height],
    bottomCenter: [pageX + left + width / 2, pageY + top + height],
    bottomRight: [pageX + left + width, pageY + top + height]
  };
};
export default function(node) {
  const { tooltip: tooltipHtml } = node.dataset;
  const div = document.createElement("div");
  div.innerHTML = tooltipHtml;
  const tooltip = div.firstElementChild;
  if (!(tooltip instanceof HTMLElement)) {
    return;
  }
  node.removeAttribute("title");
  tooltip.id = tooltip.id || `tooltip-${Math.random().toString(36).substring(7)}`;
  tooltip.setAttribute("aria-hidden", true);
  const append = () => {
    if (tooltip.getAttribute("aria-hidden") === "false") {
      return;
    }
    Array.from(document.body.children).map((child) => child.id.startsWith("tooltip") && child.remove());
    document.body.appendChild(tooltip);
    node.setAttribute("aria-describedby", tooltip.id);
    const { topCenter, bottomCenter, middleRight, middleLeft } = getAbsolutePosition(node);
    let positionX = null;
    let positionY = null;
    if (tooltip.classList.contains("bottom")) {
      [positionX, positionY] = bottomCenter;
    } else if (tooltip.classList.contains("left")) {
      [positionX, positionY] = middleLeft;
    } else if (tooltip.classList.contains("right")) {
      [positionX, positionY] = middleRight;
    } else if (tooltip.classList.contains("top")) {
      [positionX, positionY] = topCenter;
    }
    if ((tooltip.classList.contains("top") || tooltip.classList.contains("bottom")) && positionX < Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) * 0.5) {
      tooltip.style.setProperty("--arrow-offset", "80%");
    } else {
      tooltip.style.removeProperty("--arrow-offset");
    }
    tooltip.style.top = `${positionY}px`;
    tooltip.style.left = `${positionX}px`;
    tooltip.setAttribute("aria-hidden", false);
  };
  let cancelRemove = false;
  const remove = () => {
    cancelRemove = false;
    setTimeout(() => !cancelRemove && tooltip.setAttribute("aria-hidden", true), 500);
  };
  window.addEventListener("keydown", (event) => event.key === "Escape" && remove());
  node.addEventListener("mouseenter", append);
  node.addEventListener("mouseleave", remove);
  node.addEventListener("focus", append);
  node.addEventListener("blur", remove);
  tooltip.addEventListener("mouseenter", () => tooltip.setAttribute("aria-hidden", false));
  tooltip.addEventListener("mouseleave", remove);
  node.addEventListener("mouseover", () => cancelRemove = true);
  tooltip.addEventListener("mouseover", () => cancelRemove = true);
}
