"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
window.addEventListener("DOMContentLoaded", () => __async(this, null, function* () {
  const GRANTED_PERMISSION = "granted";
  const hideReminder = function() {
    const reminder = document.querySelector("#push-notifications-reminder");
    reminder.classList.add("hide");
  };
  const subscribeToNotifications = (registration) => __async(this, null, function* () {
    var _a;
    const permission = yield window.Notification.requestPermission();
    if (registration && permission === GRANTED_PERMISSION) {
      const vapidElement = document.querySelector("#vapidPublicKey");
      if (vapidElement) {
        const vapidPublicKeyElement = JSON.parse(vapidElement.value);
        const subscription = yield registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: new Uint8Array(vapidPublicKeyElement)
        });
        if (subscription) {
          yield fetch("/notifications_subscriptions", {
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": (_a = document.querySelector("meta[name=csrf-token]")) == null ? void 0 : _a.content
            },
            method: "POST",
            body: JSON.stringify(subscription)
          });
        }
      }
      hideReminder();
    } else {
      throw new Error("Permission not granted for Notification");
    }
  });
  const unsubscribeFromNotifications = (registration) => __async(this, null, function* () {
    var _a;
    const currentSubscription = yield registration.pushManager.getSubscription();
    const auth = currentSubscription.toJSON().keys.auth;
    yield fetch(`/notifications_subscriptions/${auth}`, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": (_a = document.querySelector("meta[name=csrf-token]")) == null ? void 0 : _a.content
      },
      method: "DELETE"
    });
  });
  const setToggleState = (registration, toggle) => __async(this, null, function* () {
    const currentSubscription = yield registration.pushManager.getSubscription();
    let toggleChecked = false;
    if (window.Notification.permission === GRANTED_PERMISSION) {
      hideReminder();
      if (currentSubscription) {
        const auth = currentSubscription.toJSON().keys.auth;
        const subKeys = JSON.parse(document.querySelector("#subKeys").value);
        if (subKeys.includes(auth)) {
          toggleChecked = true;
        }
      }
    }
    toggle.checked = toggleChecked;
  });
  if ("serviceWorker" in navigator) {
    const toggle = document.getElementById("allow_push_notifications");
    if (toggle) {
      const registration = yield navigator.serviceWorker.ready;
      setToggleState(registration, toggle);
      toggle.addEventListener("change", (_0) => __async(this, [_0], function* ({ target }) {
        if (target.checked) {
          yield subscribeToNotifications(registration);
        } else {
          yield unsubscribeFromNotifications(registration);
        }
      }));
    }
  }
}));
