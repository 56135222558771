"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import Accordions from "a11y-accordion-component";
import Dropdowns from "a11y-dropdown-component";
import Dialogs from "a11y-dialog-component";
import { screens } from "tailwindcss/defaultTheme";
const isScreenSize = (key) => {
  return window.matchMedia(`(min-width: ${screens[key]})`).matches;
};
const createAccordion = (component) => {
  const accordionOptions = {};
  accordionOptions.isMultiSelectable = component.dataset.multiselectable !== "false";
  accordionOptions.isCollapsible = component.dataset.collapsible !== "false";
  Object.keys(screens).forEach((key) => {
    if (!isScreenSize(key)) {
      return;
    }
    const elementsToOpen = component.querySelectorAll(`[data-controls][data-open-${key}]`);
    elementsToOpen.forEach((elem) => {
      elem.dataset.open = elem.dataset[`open-${key}`.replace(/-([a-z])/g, (str) => str[1].toUpperCase())];
    });
  });
  if (!component.id) {
    component.id = `accordion-${Math.random().toString(36).substring(7)}`;
  }
  Accordions.render(component.id, accordionOptions);
};
const createDropdown = (component) => {
  const dropdownOptions = {};
  dropdownOptions.dropdown = component.dataset.target;
  dropdownOptions.hover = component.dataset.hover === "true";
  dropdownOptions.autoClose = component.dataset.autoClose === "true";
  const isDisabled = Object.keys(screens).some((key) => {
    if (!isScreenSize(key)) {
      return false;
    }
    return Boolean(component.dataset[`disabled-${key}`.replace(/-([a-z])/g, (str) => str[1].toUpperCase())]);
  });
  if (isDisabled) {
    return;
  }
  dropdownOptions.isOpen = component.dataset.open === "true";
  const isOpen = Object.keys(screens).some((key) => {
    if (!isScreenSize(key)) {
      return false;
    }
    return Boolean(component.dataset[`open-${key}`.replace(/-([a-z])/g, (str) => str[1].toUpperCase())]);
  });
  dropdownOptions.isOpen = dropdownOptions.isOpen || isOpen;
  if (!component.id) {
    component.id = `dropdown-${Math.random().toString(36).substring(7)}`;
  }
  const autofocus = component.dataset.autofocus;
  if (autofocus) {
    component.addEventListener("click", () => setTimeout(() => document.getElementById(autofocus).focus(), 0));
  }
  const scrollToMenu = component.dataset.scrollToMenu === "true";
  if (scrollToMenu) {
    component.addEventListener("click", (event) => {
      const heightToScroll = component.getBoundingClientRect().top + window.scrollY + document.documentElement.clientTop;
      const isCollapsed = event.target.getAttribute("aria-expanded") === "false";
      if (isCollapsed) {
        return;
      }
      window.scrollTo({ top: heightToScroll, behavior: "smooth" });
    });
  }
  Dropdowns.render(component.id, dropdownOptions);
};
const createDialog = (component) => {
  const {
    dataset: _a
  } = component, _b = _a, { dialog } = _b, attrs = __objRest(_b, ["dialog"]);
  const setFocusOnTitle = (content) => {
    const heading = content.querySelector("[id^=dialog-title]");
    if (heading) {
      heading.setAttribute("tabindex", heading.getAttribute("tabindex") || -1);
      heading.focus();
    }
  };
  const modal = new Dialogs(`[data-dialog="${dialog}"]`, __spreadValues(__spreadValues(__spreadValues({
    openingSelector: `[data-dialog-open="${dialog}"]`,
    closingSelector: `[data-dialog-close="${dialog}"]`,
    backdropSelector: `[data-dialog="${dialog}"]`,
    enableAutoFocus: false,
    onOpen: (params, trigger) => {
      setFocusOnTitle(params);
      window.focusGuard.trap(params, trigger);
      params.dispatchEvent(new CustomEvent("open.dialog"));
    },
    onClose: (params) => {
      window.focusGuard.disable();
      params.dispatchEvent(new CustomEvent("close.dialog"));
    }
  }, Boolean(component.querySelector(`#dialog-title-${dialog}`)) && {
    labelledby: `dialog-title-${dialog}`
  }), Boolean(component.querySelector(`#dialog-desc-${dialog}`)) && {
    describedby: `dialog-desc-${dialog}`
  }), attrs));
  document.body.appendChild(modal.dialog);
  window.Decidim.currentDialogs = __spreadProps(__spreadValues({}, window.Decidim.currentDialogs), { [dialog]: modal });
  document.addEventListener("remote-modal:loaded", () => {
    const heading = modal.dialog.querySelector(`#dialog-title-${dialog}`);
    if (heading) {
      modal.dialog.setAttribute("aria-labelledby", `dialog-title-${dialog}`);
      setFocusOnTitle(modal.dialog);
    }
    if (modal.dialog.querySelector(`#dialog-desc-${dialog}`)) {
      modal.dialog.setAttribute("aria-describedby", `dialog-desc-${dialog}`);
    }
  });
};
const announceForScreenReader = (message, mode = "assertive") => {
  if (!message || typeof message !== "string" || message.length < 1) {
    return;
  }
  let element = document.getElementById("screen-reader-announcement");
  if (!element) {
    element = document.createElement("div");
    element.setAttribute("id", "screen-reader-announcement");
    element.classList.add("sr-only");
    element.setAttribute("aria-atomic", true);
    document.body.append(element);
  }
  if (mode === "polite") {
    element.setAttribute("aria-live", mode);
  } else {
    element.setAttribute("aria-live", "assertive");
  }
  element.innerHTML = "";
  setTimeout(() => {
    const randomIdentifier = `announcement-${(/* @__PURE__ */ new Date()).getUTCMilliseconds()}-${Math.floor(Math.random() * 1e7)}`;
    const announce = document.createElement("span");
    announce.setAttribute("data-random", randomIdentifier);
    announce.textContent = message;
    element.append(announce);
  }, 100);
};
export {
  createAccordion,
  createDialog,
  createDropdown,
  announceForScreenReader,
  Accordions,
  Dialogs,
  Dropdowns
};
